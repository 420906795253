import { Box, Center, Flex, Text } from '@chakra-ui/react';
import Illustration from 'common/Illustration';
import { Button, Illustrations } from '@pluxee-design-system/react';

interface OfferCardProps {
  buttonText: string;
  descriptionText: string;
  headerText: string;
  id: string;
  illustrationId: Illustrations;
  illustrationBg: string;
  isDisabled?: boolean;
  onClick: () => void;
}

const OfferCard = ({
  buttonText,
  descriptionText,
  headerText,
  id,
  illustrationBg,
  illustrationId,
  isDisabled,
  onClick,
}: OfferCardProps) => (
  <Box boxShadow="large" width={360} bgColor="semantic.surface.1">
    <Center
      bg={illustrationBg}
      height="13rem"
      position="relative"
      _after={{
        content: `""`,
        width: '0%',
        height: '0px',
        borderTopColor: illustrationBg,
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftColor: 'transparent',
        position: 'absolute',
        bottom: 'calc(100% - 13rem)',
        left: 'calc(50% - 1.5rem)',
        transform: 'translateY(100%)',
        borderStyle: 'solid',
        borderWidth: '1.5rem 1.5rem 0',
      }}
    >
      <Illustration illustrationId={illustrationId} width="15rem" height="15rem" />
    </Center>
    <Flex p={4} pt={16} direction="column" textAlign="center" justify="space-between">
      <Box as="header">
        <Text as="h3" variant="title.desktopCard" mb={2}>
          {headerText}
        </Text>
        <Text variant="subtitle.card" mb={10} color="semantic.text.tertiary">
          {descriptionText}
        </Text>
      </Box>
      <Button
        type="button"
        isDisabled={isDisabled}
        variant="primaryFilled"
        id={id}
        onClick={onClick}
        width="100%"
      >
        {buttonText}
      </Button>
    </Flex>
  </Box>
);

export default OfferCard;
