import { Box, Flex, Stack } from '@chakra-ui/react';
import { Illustrations, InteractiveVariants, Notification } from '@pluxee-design-system/react';
import { TextTitle } from 'common/typography';
import CampaignLogo from 'components/Affiliation/CampaignLogo';
import OfferCard from 'components/Landing/OfferCard';
import useConfig from 'data/useConfig';
import useContractSwitcher from 'data/useContractSwitcher';
import useCurrentCampaign from 'data/useCurrentCampaign';
import { I18nProps, withNamespaces } from 'i18n';
import gtm from 'trackers/gtm';
import { AffiliationStatusEnum } from 'types/common';
import routerPush from 'utils/router';

interface LandingProps extends I18nProps {
  hasMultiple: boolean;
  status: AffiliationStatusEnum;
  onDiscard: () => void;
}

const contractHandler = () => {
  gtm.trackClickManageCompany();
  routerPush('/dashboard');
};

const affiliateHandler = () => {
  gtm.trackClickRegisterCompany();
  routerPush('/affiliation');
};

const Landing = ({ hasMultiple, onDiscard, status, t }: LandingProps) => {
  const showStatusBar = status === AffiliationStatusEnum.AffiliationDraft;
  const isManageDisabled =
    !hasMultiple &&
    !(
      status === AffiliationStatusEnum.NotLogged ||
      status === AffiliationStatusEnum.Onboarding ||
      status === AffiliationStatusEnum.Affiliated
    );
  const { isoCode, selfcare: isSelfcareEnabled } = useConfig();
  const { campaign } = useCurrentCampaign(isoCode === 'be'); // omit condition isoCode === 'be' when cz ready
  const { handleClick: manageHandler } = useContractSwitcher(
    t,
    contractHandler,
    status !== AffiliationStatusEnum.NotLogged,
  );

  return (
    <Flex direction="column" p="12" alignItems="center">
      {showStatusBar && (
        <Box mt="-6" mb="12" width="100%">
          <Notification
            description={t(
              'landing_page.status.affiliationDraft',
              'You have not finished your previous registration. Do you want to continue?',
            )}
            isClosable
            buttonProps={{
              id: 'Landing_continueButton',
              onClick: affiliateHandler,
              children: t('landing_page.status.continue', 'Continue'),
            }}
            onClose={onDiscard}
            variant={InteractiveVariants.INTERACTIVE_WARNING}
          />
        </Box>
      )}
      {campaign && <CampaignLogo campaign={campaign} />}
      <TextTitle
        mb={16}
        title={t('landing_page.pageHeader', 'Welcome to merchant portal')}
        subtitle={t(
          'landing_page.subheader',
          'Manage your company or register new one in simple steps',
        )}
      />
      <Stack spacing="8" align="stretch" direction={{ base: 'column', md: 'row' }}>
        {isSelfcareEnabled && (
          <OfferCard
            buttonText={t('landing_page.portal.button', 'Proceed to Portal')}
            descriptionText={t(
              'landing_page.portal.description',
              'Is your company already registered? Manage your company on the portal.',
            )}
            headerText={t('landing_page.portal.header', 'Manage company')}
            id="Landing_portalProceedButton"
            illustrationBg="core.boldyBlue.5" // TODO: plx/semantic/color/illustration/background8
            illustrationId={Illustrations.OFFICE}
            isDisabled={isManageDisabled}
            onClick={hasMultiple ? manageHandler : contractHandler}
          />
        )}

        <OfferCard
          buttonText={t('landing_page.affiliation.button', 'Proceed to Registration')}
          descriptionText={t(
            'landing_page.affiliation.description',
            'Start accepting Pluxee products by registering your company. It is super easy!',
          )}
          headerText={t('landing_page.affiliation.header', 'Register company')}
          id="Landing_registrationProceedButton"
          illustrationBg="core.veryYellow.3" // TODO: plx/semantic/color/illustration/background9
          illustrationId={Illustrations.OFFICE}
          onClick={affiliateHandler}
        />
      </Stack>
    </Flex>
  );
};

export default withNamespaces(Landing, 'landing_page');
