import Loader from 'common/Loader';
import { discardAffiliation } from 'components/Affiliation/storeHandler';
import Landing from 'components/Landing';
import useAffiliationStatus from 'data/useAffiliationStatus';
import useSession from 'data/useSession';
import { NextPage } from 'next';
import { useCallback } from 'react';
import { AffiliationStatusEnum } from 'types/common';

const IndexPage: NextPage = () => {
  const [session] = useSession();
  const { status, mutate, multiple } = useAffiliationStatus(session?.user?.id);

  const discardHandler = useCallback(() => {
    if (session?.user?.id) {
      discardAffiliation(session?.user?.id);
      mutate(); // check if data has changed on backend
    }
  }, [mutate, session?.user?.id]);

  return status === AffiliationStatusEnum.Loading ? (
    <Loader />
  ) : (
    <Landing hasMultiple={multiple} status={status} onDiscard={discardHandler} />
  );
};

export default IndexPage;
