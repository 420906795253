import { AffiliationStatusEnum } from 'types/common';
import { hasModifiedAffiliation } from 'components/Affiliation/storeHandler';
import { useContracts } from 'api/contractApi';

/**
 * Based on a userID returns a status of all contracts and possibly a user data.
 * If you want to check single contract, consider using the hook useContractStatus.
 *
 * @param userId NextAuth.JS session userId
 * @returns {status: string}
 */
const useAffiliationStatus = (userId: string | null = null) => {
  let status = userId ? AffiliationStatusEnum.Loading : AffiliationStatusEnum.NotLogged;

  const {
    data: contracts,
    error,
    mutate,
    response,
  } = useContracts(undefined, status !== AffiliationStatusEnum.NotLogged);

  if (!error && response) {
    if (contracts?.length === 0) {
      status = AffiliationStatusEnum.NotAffiliated;
    } else if (contracts.some((contract) => contract.makeOnboarding)) {
      status = AffiliationStatusEnum.Onboarding;
    } else {
      status = AffiliationStatusEnum.Affiliated;
    }
  }

  if (error) {
    status = AffiliationStatusEnum.Error; // request had to fail
  }

  if (
    userId &&
    (status === AffiliationStatusEnum.NotAffiliated ||
      status === AffiliationStatusEnum.Onboarding ||
      status === AffiliationStatusEnum.Affiliated) &&
    hasModifiedAffiliation(userId)
  ) {
    status = AffiliationStatusEnum.AffiliationDraft;
  }

  return {
    status,
    error,
    multiple: contracts?.length > 1,
    isEmpty: !(contracts?.length > 0),
    mutate,
  };
};

export default useAffiliationStatus;
